<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <h2>Olá {{ user.nome }}</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
            offset-md="4"
          >
            <v-btn
              color="primary"
              class="mr-4"
              to="/promotor/revendedores"
              block
            >
              REVENDEDORES
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-btn
              color="primary"
              class="mr-4"
              to="/promotor/dashboard"
              outlined
              block
            >
              MINHA DASHBOARD
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
      >
        <v-card
          class="ma-0"
          outlined
        >
          <v-card-title>
            <p>Dados Pessoais</p>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="pessoa.nome"
                label="Nome"
                :rules="rules"
                dense
                outlined
              />
              <v-text-field
                v-model="pessoa.cpf"
                v-mask="'###.###.###-##'"
                label="CPF"
                :rules="[...rules, isCPFValid(pessoa.cpf) || 'CPF inválido']"
                dense
                outlined
              />
              <v-text-field
                v-model="pessoa.nascimento"
                type="date"
                label="Data de Nascimento"
                :rules="rules"
                dense
                outlined
              />
              <v-text-field
                v-model="pessoa.contatoSMS"
                v-mask="['(##) ####-####', '(##) #####-####']"
                label="Telefone (SMS)"
                :rules="[...rulesTel, isTelValid(pessoa.contatoSMS) || 'Telefone Inválido']"
                dense
                outlined
              />
              <v-checkbox
                v-model="naoPossuiEmail"
                label="NÃO POSSUI E-MAIL."
              />
              <v-text-field
                v-if="!naoPossuiEmail"
                v-model="pessoa.email"
                type="email"
                label="E-mail"
                :rules="[isEmailValid(pessoa.email) || 'E-mail inválido']"
                dense
                outlined
              />
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        offset-sm="6"
        md="3"
        offset-md="9"
      >
        <v-btn
          color="primary"
          block
          @click.stop="submit"
        >
          CADASTRAR
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { isCPFValid, isEmailValid, isTelValid, getErrorData } from '@/mixin'

  export default {

    data: () => ({
      rules: [v => !!v || 'Campo é obrigatório!'],
      rulesTel: [
        v => !!v || 'Campo é obrigatório!',
        v => (v.length >= 14 && v.length <= 15) || 'Telefone Incompleto',
      ],
      isCPFValid,
      isEmailValid,
      isTelValid,
      valid: false,
      naoPossuiEmail: false,
      pessoa: {
        nome: '',
        cpf: '',
        nascimento: '',
        contatoSMS: '',
        email: '',
      },
    }),

    computed: {
      ...mapGetters(['user']),
    },

    methods: {
      submit () {
        this.$refs.form.validate()

        if (this.valid) {
          const formData = new FormData()

          formData.append('PessoaJson', JSON.stringify(this.pessoa))

          this.$http.post('promotor/revendedor', formData)
            .then(({ data }) => {
              this.$toast.success('Revendedor salvo com sucesso')
              this.$router.push(`/promotor/revendedor/${data.cliente.id}`)
            })
            .catch(error => {
              const data = getErrorData(error)
              if (data.detail) {
                this.$swal('Novo Promotor', data.detail, 'error')
              }
            })
        } else {
          this.$toast.error('Formulário inválido!')
        }
      },
    },
  }
</script>
